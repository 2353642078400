import styled from "styled-components";

type TProps = {
  viewBoxSize: number;
  upsideDown?: boolean;
  stroke?: string;
};

export const ArrowRightIcon = ({ viewBoxSize, upsideDown, stroke = "#888888" }: TProps) => {
  const defaultViewBoxSize = 24;
  const ratio = viewBoxSize / defaultViewBoxSize;

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width={viewBoxSize}
      height={viewBoxSize}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      fill="none"
      $ratio={ratio}
      $upsideDown={!!upsideDown}
    >
      <path
        d="M10 15L13.8586 11.1414C13.9367 11.0633 13.9367 10.9367 13.8586 10.8586L10 7"
        stroke={stroke}
      />
    </StyledSvg>
  );
};

const StyledSvg = styled.svg<{ $ratio: number; $upsideDown: boolean }>`
  transform: ${({ $upsideDown }) => $upsideDown && "rotate(180deg)"};
  path {
    transform: ${({ $ratio }) => `scale(${$ratio})`};
  }
`;
