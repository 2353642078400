import { TGetMainBannerListRes } from "@/api/banner/banner.api-type";
import WhosSlider from "@/components/slider/WhosSlider";
import { EWHOS_BANNER_COLOR } from "@/domain/banner/enums/banner.enum";
import { PROJECT_CATEGORIES } from "@/domain/project/constants/project.constant";
import { EWHOS_SLIDER_BACKGROUND_COLOR } from "@/enums/component.enum";
import { EPROJECT_CATEGORIES } from "@/enums/g.project.enum";
import { useResponsiveBreakpoint } from "@/hooks/useResponsiveBreakpoint.hook";
import { WHOS_ROUTES } from "@/routes/routes.constant";
import { pathConvertId } from "@/utils/common.util";
import { generateAndTrackHackleEvent } from "@/utils/hackle.util";
import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";

type TProps = { mainBannerList: TGetMainBannerListRes["items"] };

export const MainSection = ({ mainBannerList }: TProps) => {
  const { isDesktop } = useResponsiveBreakpoint();

  if (mainBannerList.length === 0) return null;

  return (
    <Layout>
      <WhosSlider
        settings={{
          arrows: isDesktop ? true : false,
          dots: true,
          autoplay: true,
          autoplaySpeed: 4350,
          pauseOnHover: false,
          arrowWrapWidth: 1156,
          backgroundColor: EWHOS_SLIDER_BACKGROUND_COLOR.GRAY,
        }}
        sliderArrowType={3}
        sliderItems={mainBannerList}
        height={isDesktop ? 650 : 356}
        render={({
          id,
          category,
          name,
          targetId,
          image,
          titles,
          captions,
          textColor,
          partnerNames,
        }) => (
          <Link
            href={pathConvertId(
              category === EPROJECT_CATEGORIES.ARCHITECTURE
                ? WHOS_ROUTES.PROJECT_ARCHITECTURE_DETAIL
                : WHOS_ROUTES.PROJECT_INTERIOR_DETAIL,
              {
                id: targetId,
              }
            )}
            className="main-banner-link"
            onClick={() => {
              generateAndTrackHackleEvent("click_Main_heroImage", {
                Project_Type: PROJECT_CATEGORIES[category],
                Project_ID: id,
                Banner_Name: name,
              });
            }}
          >
            <Image
              src={image.path}
              fill
              alt="메인 배너"
              sizes="100vw"
              quality={100}
              priority={true}
            />
            <BannerDescription $textColor={textColor}>
              <ul className="title-item-list">
                {titles.map(({ ordering, value }) => (
                  <li key={ordering} className="title-item">
                    {value}
                  </li>
                ))}
              </ul>
              <ul className="caption-item-list">
                {captions.map(({ ordering, value }) => (
                  <li key={ordering} className="caption-item">
                    {value}
                  </li>
                ))}
              </ul>
              <p className="partner-name">{partnerNames.join(", ")}</p>
            </BannerDescription>
          </Link>
        )}
      />
    </Layout>
  );
};

const Layout = styled.div`
  .whos-slider {
    height: 650px;

    @media screen and (max-width: 767px) {
      height: 356px;
    }
  }
`;

const BannerDescription = styled.div<{ $textColor: EWHOS_BANNER_COLOR }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 100px;
  width: 100%;
  height: 100%;
  row-gap: 8px;

  @media screen and (max-width: 767px) {
    padding: 12px 16px;
  }

  .title-item-list {
    width: 100%;

    .title-item {
      width: 100%;
      color: ${({ $textColor }) => $textColor};
      font-size: 40px;
      font-weight: 800;
      line-height: 50px;
      letter-spacing: -0.5px;
      text-align: center;
      word-break: break-all;

      @media screen and (max-width: 767px) {
        font-size: 25px;
        line-height: 30px;
      }
    }
  }

  .caption-item-list {
    width: 100%;

    .caption-item {
      width: 100%;
      color: ${({ $textColor }) => $textColor};
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
      word-break: break-all;

      @media screen and (max-width: 767px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }

  .partner-name {
    margin-top: 8px;
    width: 100%;
    color: ${({ $textColor }) => $textColor};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    text-align: center;
    word-break: break-all;

    @media screen and (max-width: 767px) {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }
`;
