import {
  EPROJECT_BUILDING_FLOOR_AREA_RANGE_GRADE,
  EPROJECT_INTERIOR_USE,
  EPROJECT_LOCATION_CITY,
  EPROJECT_SORT,
  EPROJECT_USE,
} from "@/domain/project/enums/project.enum";
import { EPROJECT_CATEGORIES } from "@/enums/g.project.enum";
import { setOptions } from "@/utils/common.util";

export const PROJECT_CATEGORIES = {
  [EPROJECT_CATEGORIES.ARCHITECTURE]: "건축",
  [EPROJECT_CATEGORIES.INTERIOR]: "인테리어",
} as const;

export const PROJECT_USE = {
  "": "전체",
  [EPROJECT_USE.주거]: "주거",
  [EPROJECT_USE.상업]: "상업",
  [EPROJECT_USE.업무]: "업무",
  [EPROJECT_USE.문화]: "문화",
  [EPROJECT_USE.교육]: "교육",
  [EPROJECT_USE.스테이]: "스테이",
  [EPROJECT_USE.의료]: "의료",
  [EPROJECT_USE.종교]: "종교",
  [EPROJECT_USE.공공]: "공공",
} as const;

export const PROJECT_INTERIOR_USE = {
  "": "전체",
  [EPROJECT_INTERIOR_USE.주거]: "주거",
  [EPROJECT_INTERIOR_USE.상업]: "상업",
  [EPROJECT_INTERIOR_USE["F&B"]]: "F&B",
  [EPROJECT_INTERIOR_USE.업무]: "업무",
  [EPROJECT_INTERIOR_USE.문화]: "문화",
  [EPROJECT_INTERIOR_USE.교육]: "교육",
  [EPROJECT_INTERIOR_USE.스테이]: "스테이",
  [EPROJECT_INTERIOR_USE["살롱/스파"]]: "살롱/스파",
  [EPROJECT_INTERIOR_USE.의료]: "의료",
} as const;

export const PROJECT_LOCATION_CITY = {
  "": "전체",
  [EPROJECT_LOCATION_CITY.서울]: "서울",
  [EPROJECT_LOCATION_CITY.경기]: "경기",
  [EPROJECT_LOCATION_CITY.인천]: "인천",
  [EPROJECT_LOCATION_CITY.대구]: "대구",
  [EPROJECT_LOCATION_CITY.대전]: "대전",
  [EPROJECT_LOCATION_CITY.부산]: "부산",
  [EPROJECT_LOCATION_CITY.광주]: "광주",
  [EPROJECT_LOCATION_CITY.세종]: "세종",
  [EPROJECT_LOCATION_CITY.울산]: "울산",
  [EPROJECT_LOCATION_CITY.충청]: "충청",
  [EPROJECT_LOCATION_CITY.전라]: "전라",
  [EPROJECT_LOCATION_CITY.경상]: "경상",
  [EPROJECT_LOCATION_CITY.강원]: "강원",
  [EPROJECT_LOCATION_CITY.제주]: "제주",
  [EPROJECT_LOCATION_CITY.기타]: "기타",
} as const;

export const PROJECT_BUILDING_FLOOR_AREA_RANGE_GRADE = {
  "": "전체",
  [EPROJECT_BUILDING_FLOOR_AREA_RANGE_GRADE.ONE]: "300㎡미만",
  [EPROJECT_BUILDING_FLOOR_AREA_RANGE_GRADE.TWO]: "300㎡이상 ~ 500㎡미만",
  [EPROJECT_BUILDING_FLOOR_AREA_RANGE_GRADE.THREE]: "500㎡이상 ~ 1,000㎡미만",
  [EPROJECT_BUILDING_FLOOR_AREA_RANGE_GRADE.FOUR]: "1,000㎡이상",
} as const;

export const PROJECT_SORT = {
  [EPROJECT_SORT.APPROVED_DESC]: "최신순",
  [EPROJECT_SORT.HITS_DESC]: "인기순",
} as const;

export const PROJECT_USE_OPTIONS = setOptions(PROJECT_USE);
export const PROJECT_INTERIOR_USE_OPTIONS = setOptions(PROJECT_INTERIOR_USE);
export const PROJECT_LOCATION_CITY_OPTIONS = setOptions(PROJECT_LOCATION_CITY);
export const PROJECT_BUILDING_FLOOR_AREA_RANGE_GRADE_OPTIONS = setOptions(
  PROJECT_BUILDING_FLOOR_AREA_RANGE_GRADE
);

export const PROJECT_LIST_LIMIT = 12;
