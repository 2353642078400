import { getMainBannerList } from "@/api/banner/banner.api";
import { TGetMainBannerListRes } from "@/api/banner/banner.api-type";
import { getMainPopularExperts, getMainRecentProject } from "@/domain/main/api/main.api";
import { TMainPopularExpertRes, TMainRecentProjectRes } from "@/domain/main/api/main.api.type";
import { ArchitectureSection } from "@/domain/main/components/section/ArchitectureSection";
import { ExpertSection } from "@/domain/main/components/section/ExpertSection";
import { InteriorSection } from "@/domain/main/components/section/InteriorSection";
import { MainSection } from "@/domain/main/components/section/MainSection";
import { useTrackHackleEnterEvent } from "@/hooks/useTrackHackleEnterEvent.hook";
import { TWhosGetServerSideProps } from "@/types/props.type";
import { formatSpecificDomainName, isSameQueryKey } from "@/utils/common.util";
import { DehydratedState, QueryClient, dehydrate } from "@tanstack/react-query";
import styled from "styled-components";

type TProps = {
  dehydratedState: DehydratedState;
};

const Home = ({ dehydratedState }: TProps) => {
  const mainBannerListData = dehydratedState?.queries.find((query) =>
    isSameQueryKey(query.queryKey, ["getMainBannerList"])
  )?.state.data as TGetMainBannerListRes | undefined;
  const architectureProjectListData = dehydratedState?.queries.find((query) =>
    isSameQueryKey(query.queryKey, ["mainRecentProject", "ARCHITECTURE"])
  )?.state.data as TMainRecentProjectRes | undefined;
  const interiorProjectListData = dehydratedState?.queries.find((query) =>
    isSameQueryKey(query.queryKey, ["mainRecentProject", "INTERIOR"])
  )?.state.data as TMainRecentProjectRes | undefined;
  const expertListData = dehydratedState?.queries.find((query) =>
    isSameQueryKey(query.queryKey, ["mainPopularExperts"])
  )?.state.data as TMainPopularExpertRes | undefined;

  const mainBannerList = mainBannerListData?.items ?? [];
  const architectureProjectList = architectureProjectListData?.items ?? [];
  const interiorProjectList = interiorProjectListData?.items ?? [];
  const expertList = expertListData?.items ?? [];

  useTrackHackleEnterEvent("enter_Main", {
    Path: typeof window !== "undefined" ? formatSpecificDomainName(window.document.referrer) : "",
  });

  return (
    <main>
      <MainSection mainBannerList={mainBannerList} />
      <SectionList>
        <ArchitectureSection architectureProjectList={architectureProjectList} />
        <InteriorSection interiorProjectList={interiorProjectList} />
        <ExpertSection expertList={expertList} />
      </SectionList>
    </main>
  );
};

const SectionList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px 0 190px 0;
  gap: 80px;

  @media screen and (max-width: 767px) {
    padding: 64px 16px 0 16px;
    gap: 64px;
  }
`;

export const getServerSideProps: TWhosGetServerSideProps = async () => {
  const queryClient = new QueryClient();

  await queryClient.prefetchQuery({
    queryKey: ["getMainBannerList"],
    queryFn: () => getMainBannerList(),
  });

  await queryClient.prefetchQuery({
    queryKey: ["mainRecentProject", "ARCHITECTURE"],
    queryFn: () => getMainRecentProject("ARCHITECTURE"),
  });

  await queryClient.prefetchQuery({
    queryKey: ["mainRecentProject", "INTERIOR"],
    queryFn: () => getMainRecentProject("INTERIOR"),
  });

  await queryClient.prefetchQuery({
    queryKey: ["mainPopularExperts"],
    queryFn: () => getMainPopularExperts(),
  });

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};

export default Home;
