import { SliderNextArrow } from "@/components/icons/SliderNextArrow";
import { SliderPrevArrow } from "@/components/icons/SliderPrevArrow";
import { useDragging } from "@/hooks/useDragging.hook";
import { useHover } from "@/hooks/useHover.hook";
import { useResponsiveBreakpoint } from "@/hooks/useResponsiveBreakpoint.hook";
import Image from "next/image";
import { useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import styled from "styled-components";
import { TMainRecentProject } from "../../api/main.api.type";

type TSliderProps = {
  project: TMainRecentProject;
  sub?: boolean;
  onClick: () => void;
};

export const MainProjectSlider = ({ project, sub, onClick }: TSliderProps) => {
  const { isDesktop, isMobile } = useResponsiveBreakpoint();
  const { isHovered, onMouseEnter, onMouseLeave } = useHover();
  const { isDragging, onMouseDown, onMouseMove, onMouseUp } = useDragging();
  const [activeIndex, setActiveIndex] = useState(0);

  const totalImagesLength = project?.images?.length ?? 0;
  const prevActiveIndex = (activeIndex + totalImagesLength - 1) % totalImagesLength || 0;
  const nextActiveIndex = (activeIndex + 1) % totalImagesLength || 0;
  const slider = useRef<Slider>(null);

  const PrevArrow = ({ onClick }: any) => {
    return (
      <FadeInOutWrap $isHovered={isHovered} className="prev-arrow-wrap" onClick={onClick}>
        <SliderPrevArrow viewBoxHeight={sub ? 38 : 76} />
      </FadeInOutWrap>
    );
  };

  const NextArrow = ({ onClick }: any) => {
    return (
      <FadeInOutWrap $isHovered={isHovered} className="next-arrow-wrap" onClick={onClick}>
        <SliderNextArrow viewBoxHeight={sub ? 38 : 76} />
      </FadeInOutWrap>
    );
  };

  const settings: Settings = {
    dots: true,
    speed: 700,
    slidesToShow: 1,
    infinite: true,
    arrows: isDesktop ? true : false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    beforeChange: (_: number, nextSlide: number) => setActiveIndex(nextSlide),
  };

  const handleClickImageBtn = () => {
    if (isDragging) return;

    onClick();
  };

  return (
    <Layout
      $sub={!!sub}
      $isHovered={isHovered}
      $activeIndex={activeIndex}
      $prevActiveIndex={prevActiveIndex}
      $nextActiveIndex={nextActiveIndex}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Slider {...settings} ref={slider}>
        {project?.images?.map((image, imageIdx) => (
          <button
            key={image.id}
            className="image-btn"
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onClick={handleClickImageBtn}
          >
            <Image src={image.path} alt={`최근 건축 이미지`} fill priority={true} />
            {imageIdx === 0 && isDesktop && (
              <FadeInOutWrap $isHovered={isHovered}>
                <div className="image-btn-dim">
                  <div className="project-info-wrap">
                    <p className="use">{project.use}</p>
                    <div className="horiziontal-dividing-line" />
                    <p className="project-name">{project.projectName}</p>
                    <p className="patner-names">{project.partnerNames.join(", ")}</p>
                  </div>
                </div>
              </FadeInOutWrap>
            )}
          </button>
        ))}
      </Slider>
      {isMobile && (
        <button className="project-info-btn" onClick={handleClickImageBtn}>
          <p className="use">{project.use}</p>
          <p className="project-name">{project.projectName}</p>
          <p className="patner-names">{project.partnerNames.join(", ")}</p>
        </button>
      )}
    </Layout>
  );
};

const Layout = styled.div<{
  $sub: boolean;
  $isHovered: boolean;
  $activeIndex: number;
  $prevActiveIndex: number;
  $nextActiveIndex: number;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 8px;

  .slick-slider {
    width: 100%;
    height: 100%;

    .prev-arrow-wrap {
      position: absolute;
      top: 50%;
      left: 33px;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 1;
    }

    .next-arrow-wrap {
      position: absolute;
      top: 50%;
      right: 33px;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 1;
    }

    .slick-list {
      width: 100%;
      height: 100%;

      .slick-track {
        height: 100%;

        .slick-slide {
          height: 100%;

          > div {
            height: 100%;

            .image-btn {
              position: relative;
              width: 100%;
              height: 100%;
              overflow: hidden;

              img {
                object-fit: cover;
                transform: ${({ $isHovered }) => ($isHovered ? "scale(1.1)" : "scale(1)")};
                transition: 0.5s;
              }

              @media screen and (max-width: 767px) {
                height: 200px;
              }

              .image-btn-dim {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.35) 100%);

                .project-info-wrap {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  width: 50%;

                  .use {
                    color: var(--black-white-white, #fff);
                    font-size: 10px;
                    line-height: 10px;
                  }

                  .horiziontal-dividing-line {
                    margin: 8px 0;
                    width: 50px;
                    height: 1px;
                    background: #fff;
                  }

                  .project-name {
                    color: var(--black-white-white, #fff);
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                  }

                  .patner-names {
                    margin-top: 4px;
                    color: var(--black-white-white, #fff);
                    font-size: 14px;
                    line-height: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .slick-dots {
      display: flex !important;
      align-items: center;
      justify-content: center;
      bottom: 16px;
      opacity: ${({ $isHovered }) => ($isHovered ? 1 : 0)};
      transition: 0.3s;

      @media screen and (max-width: 767px) {
        bottom: 4px;
        opacity: 1;
      }

      li {
        display: flex;
        margin: ${({ $sub }) => ($sub ? "0 -4px" : "0 -2px")};
        width: unset;
        height: unset;

        @media screen and (max-width: 767px) {
          margin: 0 -6px;
          button::before {
            font-size: 4px !important;
          }
        }

        &:nth-child(${({ $activeIndex }) => $activeIndex + 1}) button:before {
          font-size: ${({ $sub }) => ($sub ? "8px" : "10px")};
          color: white;
        }

        &:nth-child(${({ $nextActiveIndex }) => $nextActiveIndex + 1}) button:before {
          font-size: ${({ $sub }) => ($sub ? "6px" : "8px")};
          color: #dcdcdc;
        }

        &:nth-child(${({ $prevActiveIndex }) => $prevActiveIndex + 1}) button:before {
          font-size: ${({ $sub }) => ($sub ? "6px" : "8px")};
          color: #dcdcdc;
        }

        button::before {
          opacity: 1;
          font-size: ${({ $sub }) => ($sub ? "4px" : "6px")};
          color: #c8c8c8;
          transition: 0.7s;
        }
      }
    }
  }

  .project-info-btn {
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: start;

    .use {
      color: var(--Primary-Primary_05, var(--brand-color-main-04-main, #f35935));
      font-size: 12px;
      line-height: 18px;
    }

    .project-name {
      width: 100%;
      color: var(--Mono-gray_18, #101010);
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: -0.5px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .patner-names {
      width: 100%;
      color: var(--Mono-gray_10, #888);
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

const FadeInOutWrap = styled.div<{ $isHovered: boolean }>`
  display: flex;
  opacity: 0;
  animation: ${({ $isHovered }) =>
    $isHovered ? "slider-overlay-fade-in 0.3s forwards" : "slider-overlay-fade-out 0.3s forwards"};

  @keyframes slider-overlay-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes slider-overlay-fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @media screen and (max-width: 767px) {
    opacity: 1;
    animation: none;
  }
`;

const DotIconWrap = styled.div<{ $activeViewBoxSize: number; $sub: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $activeViewBoxSize, $sub }) => $activeViewBoxSize + ($sub ? 2 : 4) + "px"};
  height: ${({ $activeViewBoxSize }) => $activeViewBoxSize + "px"};
`;
