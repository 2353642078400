import styled from "styled-components";

type TProps = {
  viewBoxHeight: number;
};

export const SliderPrevArrow = ({ viewBoxHeight }: TProps) => {
  const defaultViewBoxWidth = 38;
  const defaultViewBoxHeight = 76;
  const viewBoxWidth = (viewBoxHeight / defaultViewBoxHeight) * defaultViewBoxWidth;
  const ratio = viewBoxHeight / defaultViewBoxHeight;
  return (
    <Logo
      xmlns="http://www.w3.org/2000/svg"
      width={viewBoxWidth + 2}
      height={viewBoxHeight}
      viewBox={`0 0 ${viewBoxWidth + 2} ${viewBoxHeight}`}
      fill="none"
      $ratio={ratio}
    >
      <path
        d="M39 77L1.14142 39.1414C1.06332 39.0633 1.06332 38.9367 1.14142 38.8586L39 1"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Logo>
  );
};

const Logo = styled.svg<{ $ratio: number }>`
  path {
    transform: ${({ $ratio }) => `scale(${$ratio})`};
  }
`;
