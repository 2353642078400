import { ArrowRightIcon } from "@/components/icons/ArrowRightIcon";
import { DesignWhosSquareLogo } from "@/components/logos/DesignWhosSquareLogo";
import { TMainPopularExpertRes } from "@/domain/main/api/main.api.type";
import { PARTNER_CATEGORIES } from "@/domain/partner/constants/partner.constant";
import { useResponsiveBreakpoint } from "@/hooks/useResponsiveBreakpoint.hook";
import { useUserInfo } from "@/hooks/useUserInfo.hook";
import { WHOS_ROUTES } from "@/routes/routes.constant";
import { pathConvertId } from "@/utils/common.util";
import { generateAndTrackHackleEvent } from "@/utils/hackle.util";
import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";

type TProps = { expertList: TMainPopularExpertRes["items"] };

export const ExpertSection = ({ expertList }: TProps) => {
  const { isMobile } = useResponsiveBreakpoint();
  const { hackleLoginUserProperties } = useUserInfo();

  if (expertList.length === 0) return;

  return (
    <Layout>
      <div className="section-header">
        <div className="section-title-wrap">
          <DesignWhosSquareLogo viewBoxSize={12} viewBoxHeightOffset={5} />
          <span className="section-title">POPULAR EXPERT</span>
        </div>
        <Link
          href={WHOS_ROUTES.EXPERTS_URL}
          onClick={() =>
            generateAndTrackHackleEvent("click_Main_Expert_More", { ...hackleLoginUserProperties })
          }
          className="show-more-btn"
        >
          <span className="show-more-text">더보기</span>
          <ArrowRightIcon viewBoxSize={24} />
        </Link>
      </div>
      <ul className="expert-image-wrap-list">
        {expertList.map((expert) => (
          <li key={expert.id} className="expert-image-wrap">
            <Link
              href={pathConvertId(WHOS_ROUTES.EXPERTS_DETAIL_URL, {
                id: expert.id,
              })}
              className="expert-image-btn"
              onClick={() =>
                generateAndTrackHackleEvent("click_Main_Expert", {
                  ...hackleLoginUserProperties,
                  Expert_Type: PARTNER_CATEGORIES[expert.category],
                  Expert_ID: expert.id,
                })
              }
            >
              <div className="expert-image-container">
                <Image
                  className="expert-image"
                  key={expert.id}
                  src={expert.images.path}
                  fill
                  alt="expert"
                />
              </div>
              <div className="expert-info">
                <p className="expert-category">{PARTNER_CATEGORIES[expert.category]}</p>
                <p className="expert-name">{expert.name}</p>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      {isMobile && (
        <Link
          className="m-show-more-btn"
          href={WHOS_ROUTES.EXPERTS_URL}
          onClick={() =>
            generateAndTrackHackleEvent("click_Main_Expert_More", { ...hackleLoginUserProperties })
          }
        >
          더보기
        </Link>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 1156px;
  gap: 16px;

  @media screen and (max-width: 767px) {
    width: 100%;
    gap: 8px;
  }

  .section-header {
    display: flex;
    align-items: end;
    justify-content: space-between;

    .section-title-wrap {
      display: flex;
      align-items: center;
      gap: 8px;

      .section-title {
        color: var(--Mono-gray_15, #404040);
        font-size: 25px;
        font-weight: 800;
        line-height: 30px;

        @media screen and (max-width: 767px) {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: -0.5px;
        }
      }
    }

    .show-more-btn {
      display: flex;
      align-items: center;

      @media screen and (max-width: 767px) {
        display: none;
      }

      .show-more-text {
        color: var(--Mono-gray_10, #888);
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: -0.2px;
      }
    }
  }

  .expert-image-wrap-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media screen and (max-width: 767px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px 8px;
    }

    .expert-image-wrap {
      @media screen and (max-width: 767px) {
        width: 100%;
        min-width: 0;
        height: 100%;
      }

      .expert-image-btn {
        position: relative;
        display: block;
        width: 215px;
        height: 215px;

        &:hover {
          .expert-image {
            transform: scale(1.1);
          }

          .expert-info {
            opacity: 1;
          }
        }

        @media screen and (max-width: 767px) {
          width: 100%;
          height: 100%;
        }

        .expert-image-container {
          position: relative;
          width: 215px;
          height: 215px;
          overflow: hidden;

          @media screen and (max-width: 767px) {
            width: calc((100vw - 40px) / 2);
            height: calc((100vw - 40px) / 2);
          }

          .expert-image {
            object-fit: cover;
            object-position: left;
            transition: 0.5s;
          }
        }

        .expert-info {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: end;
          padding: 12px 4px;
          width: 100%;
          height: 100%;
          gap: 2px;
          opacity: 0;
          transition: 0.3s;

          @media screen and (max-width: 767px) {
            position: static;
            width: unset;
            height: unset;
            padding: 8px 4px;
            opacity: 1;
          }

          .expert-category {
            padding: 0 8px;
            width: 100%;
            color: var(--black-white-white, #fff);
            font-size: 14px;
            line-height: 16px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: center;

            @media screen and (max-width: 767px) {
              color: var(--Mono-gray_15, #404040);
              font-size: 12px;
              line-height: 16px;
            }
          }

          .expert-name {
            padding: 0 8px;
            width: 100%;
            color: var(--black-white-white, #fff);
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: center;

            @media screen and (max-width: 767px) {
              color: var(--Mono-gray_18, #101010);
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  .m-show-more-btn {
    padding: 12px 24px;
    border-radius: 3px;
    background: var(--Primary-Primary_05, #f35935);
    color: var(--black-white-white, #fff);
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.2px;
  }
`;
