export enum EPROJECT_SORT {
  APPROVED_DESC = "APPROVED_DESC",
  HITS_DESC = "HITS_DESC",
}

export enum EPROJECT_USE {
  주거 = "주거",
  상업 = "상업",
  업무 = "업무",
  문화 = "문화",
  교육 = "교육",
  스테이 = "스테이",
  의료 = "의료",
  종교 = "종교",
  공공 = "공공",
}

export enum EPROJECT_INTERIOR_USE {
  주거 = "주거",
  상업 = "상업",
  "F&B" = "F&B",
  업무 = "업무",
  문화 = "문화",
  교육 = "교육",
  스테이 = "스테이",
  "살롱/스파" = "살롱/스파",
  의료 = "의료",
}

export enum EPROJECT_LOCATION_CITY {
  서울 = "서울",
  경기 = "경기",
  인천 = "인천",
  대구 = "대구",
  대전 = "대전",
  부산 = "부산",
  광주 = "광주",
  세종 = "세종",
  울산 = "울산",
  충청 = "충청",
  전라 = "전라",
  경상 = "경상",
  강원 = "강원",
  제주 = "제주",
  기타 = "기타",
}

export enum EPROJECT_BUILDING_FLOOR_AREA_RANGE_GRADE {
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR",
}
