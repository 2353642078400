import { ArrowRightIcon } from "@/components/icons/ArrowRightIcon";
import { DesignWhosSquareLogo } from "@/components/logos/DesignWhosSquareLogo";
import { HoriziontalDividingLine } from "@/components/style/common.styled-componets";
import { TMainRecentProjectRes } from "@/domain/main/api/main.api.type";
import { EPROJECT_CATEGORIES } from "@/enums/g.project.enum";
import { useResponsiveBreakpoint } from "@/hooks/useResponsiveBreakpoint.hook";
import { useUserInfo } from "@/hooks/useUserInfo.hook";
import { WHOS_ROUTES } from "@/routes/routes.constant";
import { pathConvertId } from "@/utils/common.util";
import { generateHackleEvent, hackleClient } from "@/utils/hackle.util";
import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";
import { MainProjectSlider } from "../carousel/MainProjectSlider";

type TProps = {
  architectureProjectList: TMainRecentProjectRes["items"];
};

export const ArchitectureSection = ({ architectureProjectList }: TProps) => {
  const router = useRouter();
  const { isMobile } = useResponsiveBreakpoint();
  const { hackleLoginUserProperties } = useUserInfo();

  const getWhosSliderProps = (itemIdx: number) => {
    const project = architectureProjectList[itemIdx];
    const onClick = () => {
      router.push(
        pathConvertId(WHOS_ROUTES.PROJECT_ARCHITECTURE_DETAIL, {
          id: project.id,
        })
      );
      hackleClient?.track(
        generateHackleEvent("click_Main_NewArchitectProject", {
          ...hackleLoginUserProperties,
          Project_Type: EPROJECT_CATEGORIES.ARCHITECTURE,
          Project_Id: project.id,
        })
      );
    };

    return {
      project,
      onClick,
    };
  };

  if (architectureProjectList.length === 0) return null;

  return (
    <Layout>
      <div className="section-header">
        <div className="section-title-wrap">
          <DesignWhosSquareLogo viewBoxSize={12} viewBoxHeightOffset={4} />
          <span className="section-title">ARCHITECTURE</span>
        </div>
        <Link
          href={WHOS_ROUTES.PROJECT_ARCHITECTURE_URL}
          onClick={() =>
            hackleClient?.track(
              generateHackleEvent("click_Main_NewArchitectProject_More", hackleLoginUserProperties)
            )
          }
          className="show-more-btn"
        >
          <span className="show-more-text">더보기</span>
          <ArrowRightIcon viewBoxSize={24} />
        </Link>
      </div>
      <div className="slider-wrap">
        <div className="left-slider-wrap">
          <div className="first-slider-wrap">
            {<MainProjectSlider {...getWhosSliderProps(0)} />}
          </div>
        </div>
        {isMobile && <HoriziontalDividingLine $backgroundColor="#dcdcdc" />}
        <div className="right-slider-wrap">
          <div className="second-slider-wrap">
            <MainProjectSlider {...getWhosSliderProps(1)} sub />
          </div>
          {isMobile && <HoriziontalDividingLine $backgroundColor="#dcdcdc" />}
          <div className="third-slider-wrap">
            <MainProjectSlider {...getWhosSliderProps(2)} sub />
          </div>
          {isMobile && (
            <Link
              className="show-more-btn"
              href={WHOS_ROUTES.PROJECT_ARCHITECTURE_URL}
              onClick={() =>
                hackleClient?.track(
                  generateHackleEvent(
                    "click_Main_NewArchitectProject_More",
                    hackleLoginUserProperties
                  )
                )
              }
            >
              더보기
            </Link>
          )}
        </div>
      </div>
    </Layout>
  );
};

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 1156px;
  gap: 16px;

  @media screen and (max-width: 767px) {
    width: 100%;
    gap: 8px;
  }

  .section-header {
    display: flex;
    align-items: end;
    justify-content: space-between;

    .section-title-wrap {
      display: flex;
      align-items: center;
      gap: 8px;

      .section-title {
        color: var(--Mono-gray_15, #404040);
        font-size: 25px;
        font-weight: 800;
        line-height: 30px;

        @media screen and (max-width: 767px) {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: -0.5px;
        }
      }
    }

    .show-more-btn {
      display: flex;
      align-items: center;

      @media screen and (max-width: 767px) {
        display: none;
      }

      .show-more-text {
        color: var(--Mono-gray_10, #888);
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: -0.2px;
      }
    }
  }

  .slider-wrap {
    display: flex;
    gap: 15px;

    .left-slider-wrap {
      .first-slider-wrap {
        width: 764px;
        height: 438px;
      }
    }

    .right-slider-wrap {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .second-slider-wrap,
      .third-slider-wrap {
        width: 377px;
        height: 211px;
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 16px;

      .left-slider-wrap {
        .first-slider-wrap {
          width: unset;
          height: unset;
        }
      }

      .right-slider-wrap {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .second-slider-wrap,
        .third-slider-wrap {
          width: unset;
          height: unset;
        }
      }

      .show-more-btn {
        padding: 12px 24px;
        border-radius: 3px;
        background: var(--Primary-Primary_05, #f35935);
        color: var(--black-white-white, #fff);
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.2px;
      }
    }
  }
`;
